function toTwoDigits(x) {
  if (x < 10) {
    return `0${x}`;
  }
  return x.toString();
}

export default function getCurrentHoursMinutes() {
  const now = new Date();
  return `${toTwoDigits(now.getHours())}:${toTwoDigits(now.getMinutes())}`;
}
