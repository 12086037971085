import React from 'react';

import PageLayout from '../../components/PageLayout/PageLayout';
import CreateMeetingForm from '../../components/CreateMeetingForm/CreateMeetingForm';
// import InfoBox from '../components/InfoBox/InfoBox';

import './create-page.scss';

export default function CreatePage() {
  return (
    <PageLayout pageClassName="create-page" title="Create a new meeting">
      <CreateMeetingForm meetingTypes={['webex', 'microsoft-teams']} autofillRest />
      {/* <InfoBox /> */}
    </PageLayout>
  );
}
