import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { register } from '../../api';
import Button from '../../components/Button/Button';
import Logo from '../../components/Logo/Logo';
import PageLayout from '../../components/PageLayout/PageLayout';
import Spinner from '../../components/Spinner/Spinner';
import ErrorText from '../../components/ErrorText/ErrorText';
import './register-page.scss';

export default function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [registerState, setRegisterState] = useState(null);
  const navigate = useNavigate();

  const onRegisterClick = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setRegisterState('password-mismatch');
      toast.error('Passwords do not match!');
      return;
    }
    if (!role) {
      setRegisterState('role-missing');
      toast.error('Please select a role!');
      return;
    }
    setRegisterState('registering');
    try {
      await register({
        email,
        password,
        confirmPassword,
        role,
      });
      toast.success('Registration successful!');
      navigate('/');
    } catch (error) {
      toast.error('Registration failed!');
      setRegisterState('error');
    }
  };

  const registering = registerState === 'registering';

  return (
    <PageLayout includeMenu={false}>
      <div className="register-page">
        <form>
          <h1>Register</h1>
          <input
            disabled={registering}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            name="email"
          />
          <input
            disabled={registering}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            name="password"
          />
          <input
            disabled={registering}
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="confirmPassword"
            name="confirmPassword"
          />
          <select disabled={registering} value={role} onChange={(e) => setRole(e.target.value)} id="role" name="role">
            <option value="" disabled selected>
              Select Role
            </option>
            <option value="standard-user">User</option>
          </select>
          {registerState === 'role-missing' && <ErrorText center>Please select a role.</ErrorText>}
          {registerState === 'password-mismatch' && (
            <ErrorText center>Passwords do not match. Please try again.</ErrorText>
          )}
          {registerState === 'error' && <ErrorText center>Registration failed. Please try again.</ErrorText>}
          {registering ? (
            <Spinner />
          ) : (
            <Button className="register-button" type="button" onClick={onRegisterClick}>
              Register
            </Button>
          )}
          <div className="login-text">
            <p>Already have an account?</p>
            <Link className="login-link" to="/">
              Login here
            </Link>
          </div>
        </form>
        <div className="hero">
          <Logo />
          <h1 className="text-white">
            Start managing your organization with <em className="colorize">ease</em>.
          </h1>
        </div>
      </div>
    </PageLayout>
  );
}
