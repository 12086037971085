import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../api';

export function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      localStorage.removeItem('token');
      queryClient.removeQueries(['user']);
      navigate('/');
    },
    onError: (error) => {
      toast.error('Logout failed:', error);
    },
  });
}
