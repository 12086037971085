/* eslint-disable react/button-has-type */
import React from 'react';

import clsx from 'clsx';

import './button.scss';

export default function Button({ children, className, onClick, type, href, disabled }) {
  const classNames =
    'button px-8 py-3 rounded-2xl transition duration-300 ease-in text-white hover:shadow-xl hover:shadow-rose-500/100';

  if (href) {
    return (
      <a href={href} className={clsx(classNames, className)}>
        {children}
      </a>
    );
  }
  return (
    <button
      type={type}
      className={clsx(classNames, className, disabled && 'disabled')}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
