export function uriToStream(uri) {
  if (!uri) {
    return null;
  }

  if (uri.includes('zoom.us')) {
    return 'zoom';
  }
  const webexPattern = /^[\d\s]+$/;

  if (uri.includes('webex') || uri.includes('ciscopark')) {
    return 'UNSUPPORTED_WEBEX_URI';
  }

  if (webexPattern.test(uri)) {
    return 'webex';
  }

  if (uri.includes('meet.google.com')) {
    return 'meet';
  }

  if (uri.includes('teams.microsoft.com')) {
    return 'microsoft-teams';
  }

  return null;
}
export const statusOrder = ['errored', 'running', 'scheduled', 'finished'];

export function sortMeetings(meetings) {
  return meetings?.sort((meeting1, meeting2) => {
    if (meeting1.status === meeting2.status) {
      return meeting2.startTime - meeting1.startTime;
    }
    return statusOrder.indexOf(meeting1.status) - statusOrder.indexOf(meeting2.status);
  });
}
