import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { createMeetings } from '../../api';

import Button from '../Button/Button';
import ErrorText from '../ErrorText/ErrorText';
import Spinner from '../Spinner/Spinner';
import Calendar from '../Calendar/Calendar';
import PlatformSelector from '../PlatformSelector/PlatformSelector';
import CreatedMeetings from './CreatedMeetings';

import getCurrentHoursMinutes from '../../utils/getCurrentHoursMinutes';
import setHoursMinutes from '../../utils/setHoursMinutes';
import { getDurationMinutes } from '../../utils/getDurationMinutes';

import './create-meeting-form.scss';
import { toggleElementInArray } from '../../utils/toggleElementInArray';
import { isObjectEmpty } from '../../utils/isObjectEmpty';

function TimePicker({ name, label, value, onChange }) {
  return (
    <div className="time-picker">
      <label className="text-base" htmlFor={name}>
        {label}
      </label>

      <input type="time" id={name} name={name} value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}

/* eslint-disable no-unused-vars */
export default function CreateMeetingForm({ meetingTypes: selectedMeetingTypes, autofillRest }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: new Date(),
      startTime: getCurrentHoursMinutes(),
      duration: '00:45',
      platforms: [],
    },
  });

  const [meetingData, setMeetingData] = useState({});

  const mutation = useMutation({
    mutationFn: createMeetings,
    onSuccess: (data) => setMeetingData(data?.data),
  });

  const onSubmit = async ({ date, startTime, duration, primaryStream, platforms }) => {
    const data = {
      startTime: setHoursMinutes(date, startTime),
      durationMinutes: getDurationMinutes(duration),
      primaryStream,
      platforms,
    };
    mutation.mutate(data);
  };

  if (mutation.isSuccess) {
    return <CreatedMeetings meetingData={meetingData} />;
  }

  return (
    <div className="meeting-init-container">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="scheduling-container">
          <Calendar onChange={(date) => setValue('date', date)} value={watch('date')} />

          <div className="time-container">
            <TimePicker
              nmame="start-time"
              label="Start time"
              value={watch('startTime')}
              onChange={(value) => setValue('startTime', value)}
            />
            <TimePicker
              nmame="duration"
              label="Duration"
              value={watch('duration')}
              onChange={(value) => setValue('duration', value)}
            />
          </div>
        </div>

        <div className="platform-container">
          <PlatformSelector
            register={register}
            formErrors={errors}
            selectedPlatforms={watch('platforms') ?? []}
            onPlatformToggle={(value) =>
              setValue('platforms', toggleElementInArray(getValues('platforms') ?? [], value))
            }
            setValue={setValue}
            watch={watch}
          />
          <Button
            type="submit"
            disabled={mutation.isLoading || watch('platforms').length < 2 || !isObjectEmpty(errors)}
          >
            Create
          </Button>
        </div>
      </form>

      {mutation.isLoading && (
        <div style={{ marginTop: 20 }}>
          <Spinner />
        </div>
      )}

      {mutation.isError && (
        <ErrorText>
          <p>{mutation.error.message}</p>
          <p>{mutation.data?.message ?? 'Something went wrong. Please try again'}</p>
        </ErrorText>
      )}
    </div>
  );
}
