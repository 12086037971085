import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../Button/Button';
import { changePassword } from '../../api';
import 'react-toastify/dist/ReactToastify.css';
import './reset-password-popup.scss';

function ResetPasswordPopup({ onClose, onSubmit }) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const validatePassword = (password) => {
    const minLength = password.length >= 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.warning('Passwords do not match!');
      return;
    }

    if (!validatePassword(newPassword)) {
      toast.warning(
        'Password must be at least 6 characters long, contain a number, an uppercase letter, a lowercase letter, and a special character.'
      );
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await changePassword(newPassword, confirmPassword, token);
      toast.success('Password changed successfully!');
      setTimeout(() => {
        if (onSubmit) {
          onSubmit(newPassword, confirmPassword);
        }
        onClose();
      }, 2000);
    } catch (error) {
      toast.error('Failed to change password. Please try again.');
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Reset Password</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button className="confirm-button" type="submit">
            Confirm
          </Button>
          <Button className="cancel-button" type="button" onClick={onClose}>
            Cancel
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPopup;
