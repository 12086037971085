import { useQuery } from '@tanstack/react-query';
import { fetchCurrentUser } from '../api';

export const useUser = () =>
  useQuery({
    queryKey: ['user'],
    queryFn: fetchCurrentUser,
    staleTime: 1000 * 60 * 5,
    retry: 1,
  });
